import React from 'react'
import MainHeader from '../components/MainHeader'
import { Link, useNavigate } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { Bars } from 'react-loader-spinner';

const StaffList = () => {
    const navigate = useNavigate();
    const { data, dataLoading } = useFetch(endPoints.STAFFS);
    let staff_slug=(item)=>{
        let slug = item?.uuid + "_" + (item?.first_name + "-" + item?.last_name).toLowerCase()?.replaceAll(" ", "-")
        return slug
    }
  return (
 <>
 <MainHeader />
        {!dataLoading&&  <div className="content-body default-height">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-xl-11 col-xxl-10 m-auto main-page">
                          <div className="row">
                              <div className="col-xl-12">
                                  <div className="card plan-list">
                                      <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                          <div className="me-auto pe-3 mb-3">
                                              <h4 className="text-black fs-26 mb-3">Staff List</h4>
                                          </div>
                                          <div className="add-btn">
                                              <Link to="/add-staff">
                                                  <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width={20}
                                                      height={20}
                                                      viewBox="0 0 24 24"
                                                  >
                                                      <path
                                                          fill="none"
                                                          stroke="currentColor"
                                                          strokeLinecap="square"
                                                          strokeLinejoin="round"
                                                          strokeWidth={2}
                                                          d="M12 6v12m6-6H6"
                                                      />
                                                  </svg>
                                                  Add Staff
                                              </Link>
                                          </div>
                                      </div>
                                      <div className="card-body  pt-2">
                                          <div className="row">
                                              <div className="col-xl-12">
                                                  <h5>Staff</h5>
                                                  <ul className="tab-links">
                                                    {data?.data?.length>0&& data?.data?.map((item)=>{
                                                        return(

                                                      <li key={item?.id} onClick={()=>{navigate(`/edit-staff/${staff_slug(item?.attributes)}`,{state:{item}})}} className='cursor-pointer'>
                                                          <Link >
                                                                    {item?.attributes?.first_name} {item?.attributes?.last_name}
                                                              <svg
                                                                  width={19}
                                                                  height={19}
                                                                  viewBox="0 0 19 19"
                                                                  fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                              >
                                                                  <path
                                                                      d="M17.832 9.49976H1.16537"
                                                                      stroke="black"
                                                                      strokeWidth="1.375"
                                                                      strokeMiterlimit={10}
                                                                      strokeLinecap="round"
                                                                  />
                                                                  <path
                                                                      d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                      stroke="black"
                                                                      strokeWidth="1.375"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                  />
                                                              </svg>
                                                          </Link>
                                                      </li>
                                                        )
                                                    })}
                                                     
                                                      {data?.data?.length==0 &&<p className="">No Data Found</p>}
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>}
          <Bars
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass="bars"
              visible={dataLoading}
          />
 </>
  )
}

export default StaffList