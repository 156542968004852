import React, { useEffect, useState } from 'react';
import logo from "../assets/images/logo.png";
import Footer from '../components/Footer';
import SimpleHeader from '../components/SimpleHeader';
import axios from 'axios';
import baseUrl from '../baseUrl';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import endPoints from '../constants';
import { encryptData } from '../hooks/encryptionUtils';

import { v4 as uuidv4 } from 'uuid';
import SardineService from '../hooks/SardineService';
import Cookies from 'js-cookie';
const Login = () => {
  const navigate = useNavigate();
  const [toggleEye, setToggleEye] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [gotogin, setGoToLogin] = useState(false);
  const [encryptedData, setEncryptedData] = useState('');
  const [errors, setErrors] = useState({});
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const redirectRul = Cookies.get('url')
  useEffect(() => {
    const user = localStorage.getItem("skyfi");
    const detail = localStorage.getItem("user");
    const role = localStorage.getItem("skyrole");
    if (user && detail && role) {
      if (redirectRul) {
        navigate(redirectRul)
      }
      else navigate("/");
    }
  }, []);



  const role = async (tk, rol_id) => {
    if (rol_id == "") {
      const encrypted = encryptData("user");
      setEncryptedData(encrypted);
      localStorage.setItem("skyrole", encrypted);
      console.log('Encrypted:', encrypted);
      setGoToLogin(true);
      return;
    }

    const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}${endPoints.GET_STAFF_ROLE}`, {
      headers: {
        'Authorization': `Bearer ${tk}`
      }
    });

    if (resp?.status == 200) {
      if (resp?.data) {
        const foundRole = resp?.data?.find((item) => item?.id == rol_id);
        if (foundRole) {
          const encrypted = encryptData(foundRole?.name);
          setEncryptedData(encrypted);
          localStorage.setItem("skyrole", encrypted);
          setGoToLogin(true);
        } else {
          toast.error("Invalid Role Id");
        }
      }
    }
  };

  const check_auth = async (tk) => {
    const url  = `${process.env.REACT_APP_BASE_URL}${endPoints.ME}`
    console.log("User Me url: ", url);
    console.log("User Me token: ", tk);
    const resp = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${tk}`
      }
    });
    
    if (resp.status == 200) {

      if (resp?.data?.role_ids != "") {
        setUserData(resp?.data);
        role(tk, resp?.data?.role_ids);
        return
      }
      else if (resp?.data?.person == null) {
        role(tk, resp?.data?.role_ids);
        updateSardineData(resp?.data?.uuid)
        navigate("/register-personal-detail", { state: { email: resp?.data?.email, id: resp?.data?.id } });
        return;
      }
      else if (resp?.data?.person?.kyc_status?.toLowerCase() == "complete" || resp?.data?.role_ids) {
        setUserData(resp?.data);
        role(tk, resp?.data?.role_ids);
      }
      else if (resp?.data?.person?.kyc_status?.toLowerCase() == "pending" || resp?.data?.person?.kyc_status == null) {
        updateSardineData(resp?.data?.uuid)
        role(tk, resp?.data?.role_ids);

        navigate("/identity-verification", { state: true });
        return;
      }
      else {
        toast.error("Something Went Wrong!");
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({
    email: "",
    password: "",
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENTID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    session_key: uuidv4().replace(/-/g, ''),
  });

  useEffect(() => {
    if (userDetail?.session_key) {
      console.log("Going to initialize sardine");
      SardineService.initialize(userDetail?.session_key)
    }
  }, [userDetail?.session_key])


  const isValidEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!userDetail.email.trim()) {
      newErrors.email = "Email is required!";
    }

    if (userDetail?.email && !isValidEmail(userDetail?.email)) {
      newErrors.email = "Invalid email!";

    }
    if (!userDetail.password.trim()) {
      newErrors.password = "Password is required!";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}api/v3/oauth/token`, userDetail);
      if (resp.status == 200) {
        setToken(resp?.data);

        const user = JSON.stringify(resp?.data);
        localStorage.setItem("skyfi", user);
        await check_auth(resp?.data?.access_token);
      }
      setLoading(false);
    } catch (error) {
      // console.log(error);
      // if (error?.response?.data?.error)  toast.error(error?.response?.data?.error);
      // else toast.error(error?.message);
      // setLoading(false);

      if (error?.response?.data) {
        const errorData = error?.response?.data;

        // Check if the error response has details to iterate over
        if (errorData?.error) {
          toast.error(errorData?.error);
        } else if (typeof errorData === 'object') {
          Object.values(errorData).forEach(err => toast.error(err));
        } else {
          toast.error(errorData);
        }
      } else {
        toast.error(error?.message);
      }

      setLoading(false);

    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail({ ...userDetail, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const updateSardineData = (id) => {

    // SardineService.updateConfig({
    //   userIdHash: id,
    //   flow: "login" // Example flow after login
    // });
  };
  useEffect(() => {
    if (userData && token && gotogin) {
      // let userd = JSON.stringify(userData);

      const encrypted = encryptData(userData);
      if (encrypted) {
        localStorage.setItem("user", encrypted);
      }

      toast.success("Login Successful");
      if (redirectRul) {
        navigate(redirectRul)
      }
      else navigate("/");


    }

  }, [token, userData, gotogin]);

  const handleForgotPassword = async () => {
    if (!userDetail?.email?.trim()) {
      toast.error("Enter Your Email");
      return;
    }
    if (userDetail?.email && !isValidEmail(userDetail?.email)) {
      toast.error("Invalid Email");
      return;
    }
    try {
      setResetPasswordLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.FORGOT_PASSWORD}`,
        { email: userDetail?.email },);
      if (resp?.status == 200) {
        toast.success(resp?.data?.msg)
      }
      console.log(userDetail?.email)
      console.log(resp);
      setResetPasswordLoading(false);
    } catch (error) {
      setResetPasswordLoading(false);
      console.log(error)
      toast.error(error?.response?.data?.error);
      toast.error(error?.response?.data?.error[0]?.errorDesc);
    }
  }
  // toggle eye
  const handleEye = () => {
    setToggleEye(!toggleEye);
    if (passwordType == "password") {
      setPasswordType("text");
    }
    else setPasswordType("password");
  }
  return (
    <>
      <SimpleHeader />
      {/* <SardineService session_key={uuidv4().replace(/-/g, '')} /> */}
      <div className="authincation content-body default-height">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-11 col-xxl-10 m-auto main-page">
              <div className="row justify-content-center h-100 align-items-center">
                <div className="col-lg-8 col-md-10">
                  <div className="card login-part">
                    <div className="authincation-content">
                      <div className="row no-gutters">
                        <div className="col-xl-12">
                          <div className="auth-form">
                            <div className="text-center mb-1">
                              <a>
                                <img src={logo} />
                              </a>
                            </div>
                            <h4 className="text-center mb-4">
                              Sign in your account
                            </h4>
                            <form className="mt-4" onSubmit={handleSubmit}>
                              <div className="form-group">
                                <label className="mb-1 form-label">Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  placeholder="Enter Your Email"
                                  onChange={(e) => { handleChange(e) }}
                                />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                              </div>
                              <div className="mb-4 position-relative">
                                <label className="mb-1 form-label">Password</label>
                                <input
                                  type={passwordType}
                                  id="dz-password"
                                  name="password"
                                  className="form-control"
                                  placeholder="Enter Your Password"
                                  onChange={(e) => { handleChange(e) }}
                                />
                                <span className="show-pass eye">
                                  {!toggleEye && <i className="fa fa-eye-slash" onClick={handleEye} />}
                                  {toggleEye && <i className="fa fa-eye" onClick={handleEye} />}
                                </span>
                                {errors.password && <div className="text-danger">{errors.password}</div>}
                              </div>
                              <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                <div className="form-group">
                                  Forgot Password? &nbsp;
                                  {!resetPasswordLoading ? <a className='cursor-pointer' onClick={handleForgotPassword}>
                                    Click here to reset password
                                  </a> : "loading..."}
                                </div>
                              </div>
                              <div className="text-center">
                                <button type="submit" className="btn" disabled={loading}>
                                  Log In
                                </button>
                              </div>
                            </form>
                            <div className="new-account mt-3">
                              <p>
                                Don’t have an account yet?
                                <Link to="/register">Sign up</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
