import React, {  useEffect, useState } from 'react';

import axios from 'axios';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useLocation, useNavigate } from 'react-router-dom';

import endPoints from '../constants';
import { toast } from 'react-toastify';
import SimpleHeader from '../components/SimpleHeader';

import { v4 as uuidv4 } from 'uuid';
const RegisterPersonalAddress = () => {
    
    useEffect(() => {
        const user = localStorage.getItem("skyfi");
        const detail = localStorage.getItem("user");
        const role = localStorage.getItem("skyrole");
        if (user && detail && role) {
            navigate("/");
        }
    }, []);
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    console.log("location", location?.state);
    const [businessAddress, setBusinessAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        regionCode: "",
        zipCode: "",
    });
    const [errors, setErrors] = useState({
        address1: "",
    });
    const [addressInput, setAddressInput] = useState('');

    const handleChange = (e) => {
        const { value, name } = e.target;
        setBusinessAddress({ ...businessAddress, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleSelect = async (address) => {
        setAddressInput(address);
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;
           
            const newAddress = {
                address1: address,
                // address2: '',
                city: '',
                regionCode: '',
                zipCode: ''
            };

            addressComponents.forEach(component => {
                const types = component.types;
                // console.log(component);
                if (types.includes('locality')) {
                    newAddress.city = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.regionCode = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode = component.long_name;
                }
            });

            setBusinessAddress(newAddress);
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isError = false;
        const newErrors = { ...errors };
        for (const key in businessAddress) {
            if (businessAddress.hasOwnProperty(key)  && key !== 'address2') {
                if (businessAddress[key].trim() === "") {
                    newErrors[key] = `Required`;
                    isError = true;
                }
            }
        }
        if (isError) {
            setErrors(newErrors);
            return;
        }

        try {
            setLoading(true);
            console.log("businessAddress", businessAddress);
            let customer = {
                "session_key": uuidv4().replace(/-/g, ''),
                "customer": {
                    "id": `${location?.state?.id}`,
                    "first_name": location?.state?.userData?.firstName,
                    "last_name": location?.state?.userData?.lastName,
                    "date_of_birth": location?.state?.userData?.dob,
                    "id_number": location?.state?.userData?.ssn,
                    "address": {
                        "address_type": "1",
                        address_1: `${businessAddress.address1}`,
                        address_2: `${businessAddress.address2}`,
                        city: `${businessAddress.city}`,
                        region_code: `${businessAddress.regionCode}`,
                        zip_code: `${businessAddress.zipCode}`,
                        country_code: "US"
                    },
                    "email": `${location?.state?.userData?.email}`,
                    "phone": `${location?.state?.userData?.phone}`
                }
            }
            
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.CUSTOMERS}`, customer,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {
                toast.success("User Created");
                setBusinessAddress({
                    address1: "",
                    address2: "",
                    city: "",
                    regionCode: "",
                    zipCode: "",
                });
                navigate("/identity-verification",{state:true});
            }
            setLoading(false);
           
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.error);
            // toast.error(error?.response?.data?.error[0]);
            console.log("Error:", error);
        }
    };
    // console.log(businessAddress);
   

    return (
        <>
            <SimpleHeader />
            <div className="authincation content-body default-height">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-11 col-xxl-10 m-auto main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4">Business Address</h4>
                                                        <form className="mt-4" onSubmit={handleSubmit}>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Address 1</label>
                                                                <PlacesAutocomplete
                                                                    value={addressInput}
                                                                    onChange={setAddressInput}
                                                                    onSelect={handleSelect}
                                                                >
                                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                        <div>
                                                                            <input
                                                                                {...getInputProps({
                                                                                    placeholder: 'Enter Address 1',
                                                                                    className: 'form-control',
                                                                                    name: 'address1',
                                                                                })}
                                                                            />
                                                                            {errors.address1 && <small className="text-danger">{errors.address1}</small>}
                                                                            <div className="autocomplete-dropdown-container">
                                                                                {loading && <div>Loading...</div>}
                                                                                {suggestions.map((suggestion, idd) => {
                                                                                    const className = "cursor-pointer"
                                                                                    const style = {
                                                                                        backgroundColor: suggestion.active
                                                                                            ? "#41B6E6"
                                                                                            : "#fff",
                                                                                        border: suggestion.active
                                                                                            ? "1px solid #41B6E6"
                                                                                            : "1px solid #ccc",
                                                                                        width: "410px",
                                                                                        marginLeft: "10px",
                                                                                        marginTop: "5px",

                                                                                    };
                                                                                    return (
                                                                                        <div
                                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                                className,
                                                                                                style,
                                                                                            })}
                                                                                        >
                                                                                            <span key={idd}>{suggestion.description}</span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </PlacesAutocomplete>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Address 2</label>
                                                                <small className="form-text text-red" style={{marginLeft:'8px'}}>
                                                                    (Apartment, Suite, Etc)
                                                                </small>
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={2}
                                                                    placeholder="Enter Address 2"
                                                                    spellCheck="false"
                                                                    name="address2"
                                                                    value={businessAddress.address2}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>


                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">City</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter City"
                                                                    name="city"
                                                                    disabled
                                                                    value={businessAddress.city}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.city && <small className="text-danger">{errors.city}</small>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Region Code</label>
                                                                <input
                                                                    type="text"
                                                                    disabled
                                                                    name="regionCode"
                                                                    className="form-control"
                                                                    placeholder="Enter Region Code"
                                                                    value={businessAddress.regionCode}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.regionCode && <small className="text-danger">{errors.regionCode}</small>}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Zip Code</label>
                                                                <input
                                                                    type="text"
                                                                    name="zipCode"
                                                                    disabled
                                                                    className="form-control"
                                                                    placeholder="Enter Zip Code"
                                                                    value={businessAddress.zipCode}
                                                                    onChange={handleChange}
                                                                />
                                                                {errors.zipCode && <small className="text-danger">{errors.zipCode}</small>}
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="btn" disabled={loading}>
                                                                    {loading ? "Loading" : "Create"}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterPersonalAddress;
