import React, { useEffect } from 'react'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const RedirectComponent = () => {
    const url=Cookies.get("url");
    const navigate=useNavigate();
    useEffect(()=>{
      navigate(url)
    console.log("url",url);
    },[])
  return (
    <></>
  )
}

export default RedirectComponent