import React, { useEffect, useState } from 'react'
import SimpleHeader from '../components/SimpleHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import useFetch from '../hooks/useFetch';
import endPoints from '../constants';
import { toast } from 'react-toastify';
import { encryptData } from '../hooks/encryptionUtils';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';

const IdentityVerification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (!location?.state) {
            navigate("/");
            return;
        }
    }, [location?.state])
    console.log(location);
    const [fetch, setFetch] = useState(0);
    const [generateIdLoading, setGenerateIdLoading] = useState(false);
    const { data: me, dataLoading: meLoading } = useFetch(`${endPoints.ME}`, fetch);
    // const { data: kycUpdate, dataLoading: kycUpdateLoading } = useFetch(`${endPoints.UPDATE_KYC}`,kycUpdteCount);
    const updateKycStatus = async () => {

        try {
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.post(`${process.env.REACT_APP_BASE_URL}${endPoints.UPDATE_KYC}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp?.status == 200) {
                const encrypted = encryptData(me);
                if (encrypted) {
                    localStorage.setItem("user", encrypted);
                    // navigate("/");
                    toast.success("KYC Updated")
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }
    useEffect(() => {
        setFetch(fetch + 1);

    }, [])
    useEffect(() => {
        if (me && me?.person?.kyc_status == null && me?.person?.verification_id) {
            updateKycStatus();
            setFetch(fetch + 1);

        }
        else if (me && me?.person?.kyc_status?.toLowerCase() == "complete") {
            const encrypted = encryptData(me);
            const encryptedRole = encryptData("user");
            if (encrypted && encryptedRole) {
                localStorage.setItem("user", encrypted);
                localStorage.setItem("skyrole", encryptedRole);
                // navigate("/");
                navigate("/", { replace: true });
                toast.success("Login Successful")
            }
        }

        // else if (me && me?.person?.kyc_status == "pending")
    }, [me])
    const user = localStorage.getItem("skyfi");
    const tk = JSON.parse(user);
  
    const handleLogIn = async () => {

        try {
            setGenerateIdLoading(true);
            const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}${endPoints.GENERATE_IDENTITY_URL}`,
                
                {
                    headers: {
                        'Authorization': `Bearer ${tk?.access_token}`
                    }
                }
            );
            if (resp?.status == 200) {
                const link = document.createElement('a');
                link.href = `${resp.data.url}`;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            setGenerateIdLoading(false);
            console.log();
        } catch (error) {
            setGenerateIdLoading(false);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
            // toast.error('somthing went wrong')
        }
    }
    return (
        <>
            <SimpleHeader />
            {!meLoading && <div className="content-body default-height">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-11 col-xxl-10 m-auto main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="verify-identity">
                                                        <svg
                                                            className="mb-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={80}
                                                            height={80}
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <g fill="none" stroke="currentColor" strokeWidth="1.5">
                                                                <path
                                                                    d="M9.781 3.89c.564-.48.846-.72 1.14-.861a2.5 2.5 0 0 1 2.157 0c.295.14.577.38 1.14.861c.225.192.337.287.457.367a2.5 2.5 0 0 0 .908.376c.141.028.288.04.582.064c.739.058 1.108.088 1.416.197a2.5 2.5 0 0 1 1.525 1.524c.109.309.138.678.197 1.416c.023.294.035.441.063.583c.064.324.192.633.376.907c.08.12.176.232.367.457c.48.564.721.846.862 1.14a2.5 2.5 0 0 1 0 2.157c-.14.294-.381.576-.862 1.14a5.25 5.25 0 0 0-.367.457a2.497 2.497 0 0 0-.376.907c-.028.142-.04.289-.063.583c-.059.738-.088 1.108-.197 1.416a2.5 2.5 0 0 1-1.525 1.524c-.308.11-.677.139-1.416.197c-.294.024-.44.036-.582.064a2.5 2.5 0 0 0-.908.376a5.25 5.25 0 0 0-.456.367c-.564.48-.846.72-1.14.861a2.5 2.5 0 0 1-2.157 0c-.295-.14-.577-.38-1.14-.861a5.263 5.263 0 0 0-.457-.367a2.5 2.5 0 0 0-.908-.376a5.277 5.277 0 0 0-.582-.064c-.739-.058-1.108-.088-1.416-.197a2.5 2.5 0 0 1-1.525-1.524c-.109-.308-.138-.678-.197-1.416a5.186 5.186 0 0 0-.063-.583a2.5 2.5 0 0 0-.376-.907c-.08-.12-.176-.232-.367-.457c-.48-.564-.721-.846-.862-1.14a2.5 2.5 0 0 1 0-2.157c.141-.294.381-.576.862-1.14c.191-.225.287-.337.367-.457a2.5 2.5 0 0 0 .376-.907c.028-.142.04-.289.063-.583c.059-.738.088-1.107.197-1.416A2.5 2.5 0 0 1 6.42 4.894c.308-.109.677-.139 1.416-.197c.294-.024.44-.036.582-.064a2.5 2.5 0 0 0 .908-.376c.12-.08.232-.175.456-.367Z"
                                                                    opacity=".5"
                                                                />
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="m8.5 12.5l2 2l5-5"
                                                                />
                                                            </g>
                                                        </svg>
                                                        <strong>{me?.person?.kyc_status == "pending" ? "Account is in review." : "You must verify your identity."}</strong>
                                                        <p>
                                                            {me?.person?.kyc_status == "pending" ? `The underwriting team will reach out via the email you provided ${'\n'} for more information.` : "Please have your ID ready and tap the button below to proceed."}
                                                        </p>
                                                        {me?.person?.kyc_status == null && <button disabled={generateIdLoading} onClick={() => { handleLogIn() }}>{generateIdLoading?"Loading...":"Proceed to Verify"}</button>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Bars
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={meLoading}
            />
        </>
    )
}

export default IdentityVerification