import React, { useEffect, useState } from 'react';
import MainHeader from '../components/MainHeader';
import axios from 'axios';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import baseUrl from '../baseUrl';
import endPoints from '../constants';
import { toast } from 'react-toastify';

const UpdateContactAddress = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    if (!location?.state) {
        navigate(`/contact-details/${params?.acc_id}/${params?.contact_id}`);
    }
    console.log("param contact details", params);
    const [loading, setLoading] = useState(false);
    console.log("location", location.state);
    useEffect(()=>{
if(!params?.acc_id || !params?.contact_id ||location?.state?.attributes){
    navigate(`/update-contact/${params?.acc_id}/${params?.contact_id}`);
    return
}
    },[location?.state,params])
    const [contactAddress, setContactAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        regionCode: "",
        zipCode: "",
    });
    const [addressInput, setAddressInput] = useState('');
    useEffect(() => {
        setContactAddress({
            ...contactAddress,
            address1: location?.state?.details?.attributes?.address_1,
            address2: location?.state?.details?.attributes?.address2,
            city: location?.state?.details?.attributes?.city,
            regionCode: location?.state?.details?.attributes?.region_code,
            zipCode: location?.state?.details?.attributes?.zip_code,
           
        });
        setAddressInput(location?.state?.details?.attributes?.address_1)
    }, [location?.state])

    const handleChange = (e) => {
        const { value, name } = e.target;
        setContactAddress({ ...contactAddress, [name]: value });

    };

    const handleSelect = async (address) => {
        setAddressInput(address);
        try {
            const results = await geocodeByAddress(address);
            const addressComponents = results[0].address_components;
         
            const newAddress = {
                address1: address,
                address2: '',
                city: '',
                regionCode: '',
                zipCode: ''
            };

            addressComponents.forEach(component => {
                const types = component.types;
                // console.log(component);
                if (types.includes('locality')) {
                    newAddress.city = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                    newAddress.regionCode = component.short_name;
                }
                if (types.includes('postal_code')) {
                    newAddress.zipCode = component.long_name;
                }
            });

            setContactAddress(newAddress);
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    console.log("contact", location?.state?.details);
    const handleSubmit = async (e) => {
        e.preventDefault();
       


        try {
            setLoading(true);
            //   console.log(location?.state?.first_name);
            let contact = {
                phone: location?.state?.data?.phone?.slice(2),
                account_id: location?.state?.uuid?.split("_")[0],
                address_type: 1,
                email: location?.state?.data?.email,
                last_name: location?.state?.data?.last_name,
                first_name: location?.state?.data?.first_name,
                address_1: contactAddress.address1,
                address_2: contactAddress.address2,
                city: contactAddress.city,
                region_code: contactAddress.regionCode,
                zip_code: contactAddress.zipCode,
                country: "US"

            }
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.put(`${process.env.REACT_APP_BASE_URL}${endPoints.CONTACTS}/${params?.contact_id}`, contact,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            if (resp.status == 200) {
                navigate(`/view-contacts/${params?.acc_id}`, { state: { uuid: location?.state?.uuid } });
                toast.success("Contact Updated", { state: { uuid: location?.state?.uuid } });
                setContactAddress({
                    address1: "",
                    address2: "",
                    city: "",
                    regionCode: "",
                    zipCode: "",
                });
            }
            setLoading(false);
            // console.log("Response:", resp);
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.error);
            console.log("Error:", error);
        }
    };

    return (
        <>
            <MainHeader />
            <div className="authincation content-body default-height">
                <div className="container h-100">
                    <div className="row justify-content-center h-100 align-items-center">
                        <div className="col-xl-11 col-xxl-10 m-auto main-page">
                            <div className="row justify-content-center h-100 align-items-center">
                                <div className="col-lg-8 col-md-10">
                                    <div className="card login-part">
                                        <div className="authincation-content">
                                            <div className="row no-gutters">
                                                <div className="col-xl-12">
                                                    <div className="auth-form">
                                                        <h4 className="mb-4">Contact Address</h4>
                                                        <form className="mt-4" onSubmit={handleSubmit}>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Address 1</label>
                                                                <PlacesAutocomplete
                                                                    value={addressInput}
                                                                    onChange={setAddressInput}
                                                                    onSelect={handleSelect}
                                                                >
                                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                        <div>
                                                                            <input
                                                                                {...getInputProps({
                                                                                    placeholder: 'Enter Address 1',
                                                                                    className: 'form-control',
                                                                                    name: 'address1',
                                                                                })}
                                                                            />
                                                                           
                                                                            <div className="autocomplete-dropdown-container">
                                                                                {loading && <div>Loading...</div>}
                                                                                {suggestions.map((suggestion, idd) => {
                                                                                    const className = "cursor-pointer"
                                                                                    const style = {
                                                                                        backgroundColor: suggestion.active
                                                                                            ? "#41B6E6"
                                                                                            : "#fff",
                                                                                        border: suggestion.active
                                                                                            ? "1px solid #41B6E6"
                                                                                            : "1px solid #ccc",
                                                                                        width: "410px",
                                                                                        marginLeft: "10px",
                                                                                        marginTop: "5px",

                                                                                    };
                                                                                    return (
                                                                                        <div
                                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                                className,
                                                                                                style,
                                                                                            })}
                                                                                        >
                                                                                            <span key={idd}>{suggestion.description}</span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </PlacesAutocomplete>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Address 2</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    rows={2}
                                                                    placeholder="Enter Address 2"
                                                                    spellCheck="false"
                                                                    name="address2"
                                                                    value={contactAddress.address2}
                                                                    onChange={handleChange}
                                                                />

                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">City</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter City"
                                                                    name="city"
                                                                    disabled
                                                                    value={contactAddress.city}
                                                                    onChange={handleChange}
                                                                />
                                                             
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Region Code</label>
                                                                <input
                                                                    type="text"
                                                                    disabled
                                                                    name="regionCode"
                                                                    className="form-control"
                                                                    placeholder="Enter Region Code"
                                                                    value={contactAddress.regionCode}
                                                                    onChange={handleChange}
                                                                />
                                                              
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-1 form-label">Zip Code</label>
                                                                <input
                                                                    type="text"
                                                                    name="zipCode"
                                                                    disabled
                                                                    className="form-control"
                                                                    placeholder="Enter Zip Code"
                                                                    value={contactAddress.zipCode}
                                                                    onChange={handleChange}
                                                                />
                                                                
                                                            </div>
                                                            <div className="text-center">
                                                                <button type="submit" className="btn" disabled={loading}>
                                                                    {loading ? "Loading" : "Update"}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpdateContactAddress;
