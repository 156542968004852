import React, { createContext, useState } from 'react';
// Create a context
const MyContext = createContext();
const MyProvider = ({ children }) => {
    const [updatebusiness, setUpdateBusiness] = useState([]);
    console.log("updatebusiness", updatebusiness);
    
    return (
        <MyContext.Provider value={{ updatebusiness, setUpdateBusiness }}>
            {children}
        </MyContext.Provider>
    );
};
export { MyContext, MyProvider };