import React from 'react'
import TransactionsHelper from '../hooks/TransactionsHelper.tsx'
import formatDate from '../hooks/formattDate.js'

const TransactionTile = ({
    item,accountId
}) => {

    let getTransactionType = TransactionsHelper.getTransactionType(item?.attributes)
    let transactionType = getTransactionType + " " + `${getTransactionType == "ACH" ? TransactionsHelper.isDebit(item?.attributes,accountId) ? "OUT" : "IN" : ""}`

  return (
    <div className="col-md-6 col-sm-6" key={item?.id}>
    <div className="trans-box">
        <div className="top">
          
            <div className="icon">
                {TransactionsHelper.isDebit(item?.attributes,accountId) && !TransactionsHelper.isCardTransaction(item?.attributes?.txn_type) &&
            
                <svg
              width={43}
              height={43}
              viewBox="0 0 43 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect width={43} height={43} fill="url(#pattern0_286_353)" />
              <defs>
                <pattern
                  id="pattern0_286_353"
                  patternContentUnits="objectBoundingBox"
                  width={1}
                  height={1}
                >
                  <use xlinkHref="#image0_286_353" transform="scale(0.0232558)" />
                </pattern>
                <image
                  id="image0_286_353"
                  width={43}
                  height={43}
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAEyUlEQVR4Ac1Z23XbOBAdUtS/UsFqKwhdQZQKouTY35ErsFxBlArsVGD7O3GirUDcCqJUEKWC1b8lYu+AIAlAxIOKfZw5h4cyCMxcAPO4gBP6A0ScnV1QWc4pTdf08HCZLJebrn4JPbOI09MPeC2ahiTZAPDrLsDPCvYAaC0OwM8G1gm0lg7AzwI2CLQWC/CjgBXT6bjR7wiOpm8s0EZhC7g3WAksy6ZQ8goRnOM97ui2lkaE+JR8+VL0BLrFM6JDwCfRYGFoghcbm1C8bGm3+xurslU6vuOVGyCIPmJSN60hcYv2X8qWDvg8pQiQeFb4ueoJlGWEXcgN8DpQbC/AbexB2I0F8SQMIGKTBoB+CIKsjBXas9a+FrobYJXP2TXk48ilBmCsJp4l/rxkPZ1uAL8c0WBwhY6zjs9bGLuTSna7db3FB+OxqqFgk30r91q1DeI2ub8/7+qbdWoYDAqAeUk2SKJPAHjdBVAX9d3b5xg5AIuZXuFlAhXiB+33U9dKydXhrPDwUMSs5rFigJWEQog5mUDvsC0zlwKVY1foRzQcbvD3SWjlj5XUMCrEgnoAlZJlM63/GICn9ETSZoPBYEF6MubUst/PfYNlINm+LcRLeiJJldExjL43vlSpxbmdcJkcE/wOcPZKzvHtp16CHxWsWlUNCdKHJ1AkkLL85ii1lTtk2TeKkSSJ9u/aDV4Zrfv9R+8onpwLaCu5ePduFuhDyefPXET0QvKPq2/apJ1WYtKPObmyPGcOQHaJTNOoYEN1OsHrNXS8QEAvXf04deVWm3NmrfZk3PxGuU2+fr2VP6fTa2y/TkD+okgxyrJDUhizwa4pLDohGdfBpAKyaL4J8S89omQwZs4e9T5iHIN402rJfsKdChksFUB+ttjS6whddTGaSlK031+6stBBuY2qPkmygOI3VutEVjGiaUP/YoByrhbiWunliTOXXXT15Wwwop4iI5jpmxNBj9Rl8l0e6/RzBmuspKJ3YeEt4yzAdLGbYUWlrj7CAfbLaBkOx6FBipmtkJpusMJrRPILPMyNj0ld5spWRxoHWBgzkYgJhWWu9b9ofoPrWv3CqcvORkI4A5wDzP7IgROK4vYE2qauLXblvQoyvV9I7OrpBJuqVKUrzYN+K4RZOJC68PzXRHUtZXnnVXNYPde+6pmqVKUnbz6ReqkhfDGcPzl9lWXh7SPEzGrxTq4mMrbxCx/FC6auaqfeepkbU0yblu52Tl7QgFV1udDaeXVvfAMB+FaRl8L8IE+9J4pNdQNlN2OKaTT6aWkDVolNCyfq3sANuFJu1n/4aZC1DYc3hq9WpxI/LSUNrFxdvnwwZRECrC4hdKNebiFztH26ANCYU7FxySG3J8v4wsFmYgtMxjlz6d/D4QR8YOniFko3b/3E/IDLu/t7f0B3gW0MDwarg5NAdSt4HsM7D3Senc0wlqveyAL6A0DzWD2u66NuwJUUMn+W5dJ7oKxWcoafHPF5p57d7m2fO4bEY4y39qrj9GoaZEKj1/OKNU28Z7QeWx8FttF7ejqX9T98QIywdrwryeExnZRbLIjr+HGgoy/1fNL/mp6DhclOxc7cHKK+t02Su2NX0pbf+geICqIcwEbgC1yVtnjzkWbzFJdz/wPsUG/E5axg2AAAAABJRU5ErkJggg=="
                />
              </defs>
            </svg>

             }
                {TransactionsHelper.isDebit(item?.attributes,accountId) && TransactionsHelper.isCardTransaction(item?.attributes?.txn_type) && 
       
                <svg
                        width={40}
                        height={31}
                        viewBox="0 0 40 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <rect
                            width={40}
                            height="30.8772"
                            fill="url(#pattern0_290_912)"
                        />
                        <defs>
                            <pattern
                                id="pattern0_290_912"
                                patternContentUnits="objectBoundingBox"
                                width={1}
                                height={1}
                            >
                                <use
                                    xlinkHref="#image0_290_912"
                                    transform="matrix(0.0215539 0 0 0.0279221 0.0150376 0.0227273)"
                                />
                            </pattern>
                            <image
                                id="image0_290_912"
                                width={45}
                                height={35}
                                xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAjCAYAAAAAEIPqAAABmUlEQVR4Ae2YS26DMBCGZxzWXKC5AL1Auus625CH1F6gK3qP7pJDRMrDqy5zgbLqrr1AewGkLMN0DBUFBxmKImQiPonHODPRL3ts7EH4hRaLOzidAn71+HLBLkIQQuJ2K5WB6kbzeQBx/Ay2g7jC3W6JLNhnwS/QFeL40eGbrzVHfH2CLSDeANEws4UIHH6Mcg5f3P33YBk0nb7D3zzzRPFX+gY7yY+8K6CDOAWL84dmMxtXEZXXmVEUnSZ8AJbTyfToRbdFL7otrmCd5i0g7vcPYBn8GV9DbrvRp0db9KLbohfdFlexTp9B47ELrnurt+Nm81bHrww99r8YRdNkMuSD5CvXQ87qIHxYSI7zVX6l/5uLbYI5PQYDdVIvF0IU1PKrim2AWXQcm4bxo6afObYBxvRAKUMeyid+LeYqUQTHo6z0K0OLbULlROTcO/DjcCm/S9DZdTrK2R7YiCqN5U19r6pKY5ZVmvTSc+iwwCULXWdNae1jCLZCJIWa+YnwLkC0Yr0SM9v3fe5x9ZEYgV2kpWfu2KSDmR9nyJRallt0QAAAAABJRU5ErkJggg=="
                            />
                        </defs>
                    </svg>
               
                }
                
                {!TransactionsHelper.isDebit(item?.attributes,accountId) && !TransactionsHelper.isCardTransaction(item?.attributes?.txn_type) && 
    
                <svg
             width={43}
             height={43}
             viewBox="0 0 43 43"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink"
           >
             <rect width={43} height={43} fill="url(#pattern0_290_898)" />
             <defs>
               <pattern
                 id="pattern0_290_898"
                 patternContentUnits="objectBoundingBox"
                 width={1}
                 height={1}
               >
                 <use xlinkHref="#image0_290_898" transform="scale(0.0232558)" />
               </pattern>
               <image
                 id="image0_290_898"
                 width={43}
                 height={43}
                 xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAFNklEQVR4Ae1ZXVIbRxDuHkkuV6pi9gYZSOUZqEpwUnlA3ICcADgBcALDCSxOgHwDfIKIB5cr4CrEASyWE2RxqlLEYmfSPbuSdpadmV1ZKfLgjwftz+zMt70933w9IPwPEL2XXdUSO3Q4/LQxOnG1Q3hiRFcy0mNxw4d8jhqOk5ejo6q2Ap4a97AGOVGGauF3rqZPT7YBvpL9r9CGOWBmb1tsouJ802vWTYQENMY0Uc5Bq7PklziGBaE2WSIoieAOkTjQdEq/uZZUCArCmkbYpg+3T7N9PVmPE1gAgmRZWtSDeKWJpCHYDDKf7QNYALw5y5+bNZCjCX4kqHXMv6XrMaXBAL4AHKzJsTOyLy5XOJpHVfcQ9AAUvoVUDeAbIlT4zKbzLJoSnsNZ8blvP3y/I5Tu8bESePDXjx/fuMbntNMtPNVj7C5dLg+xrbfQRRQriBqSqT6eN1pLFytX9DOdkER4tzVOb3VL/D65pgX2xTg9zq/J2di4165JNMZU7blImmj+DTL5NR6CD6hZKaanFOU+eYI+Wk2ULBM1SNPYiqzJ0cJbZtDXmOptlwRln2vaeYwd5Zz9+aeltiihASZ+QZQGPS21o8F116uVQuzCLAoSPkPX1ZT7oRffogDE0JCoGWpykTR0H+zQ86ffCmokzkxIdt6KfM2bEC47MJMGeVRvbA64l2x87Ls6msxWatkt36O07H36aXQIHoRSosoqZpHNPuVsMMC+j6hpY/L0MdF8oIMXH1ZOfc/7IuzytIYsRWLHupimTv1jRJcrvEhIXxvyDbs8YaEhYa/5ji5kJuAzBFcdWiysl6NcOuH8pgNrEVCd1g4EMCHMJEm2fnMRZbRJmbvFRZcGfgthFJ1WkmyMzHJMejuk5Xl72pcqOTIPYfo5CrUT9CetK6k6gzAshZis35lyzD4p5f4QFoi2Br1atHn3lAbBp1APKdG7+RkXfFcvLlYGQsMtveweCNxnX4tpegw1EL37YU110n0BeO6b2Lh0sWzN6ruNUbDirV7pZqgjXdO+suqWPYM0hBQcJj+PelVtBSW2hIbgCcgTwnW/jnRNMXFoOVQbV11NBUUhLl4o+kcvnqkeR8G1EtWRrqagyIq74oX7ewiSNV53LP7UAl6T6T7EsVqfV7qgYy/PItW3rqaU03ZknrdaXQigaCE1ildsDU1qtGlyFdvVkS6lu6ULQzfZ1L6pBGxCGEXpkrzOm6Oxnf9k1oOFIpV19ngPbjUS5dKDorEdzFuWrhkiUoarpT+WbzSUFQKvfd3kL1mMfuwz8MIIOVK5UugD/rGNzSOuD7qsBBEgSoBSvqeqBz4IPCqe0iIy8DavGpwmzr4vuiHpMn2yxfSYdmMREcsG6jhINjMu1kSTvFfge5ANB9dlj6WLyyAy7UGLiZYOG1sa2L2ZWhiWoOINFnYunX0PU+d91PjGfk4fhVwbS1/ZC4saS/OUbPIyPivlLlefveid9MuPUgP7HIYhouXq2XjYGntidnVr1mm8KpcavvXaPPde7lK1IZm4L6rVZb6+vtu4qWUlH5kWjqTuGAmyJthk82GeXUF2Vbrz8LqiDMqK0pp9YnXn1YShIWnjDWjXpTzr5yHqJJsPFNiQ0EOSp3PK0ZjyJKZfXq0koIhoFVyljrliiKoHNdtQe02/kte7Gq39jD1HZOYB7TbCia/O8qHWv5bMssg7eo7Suw5YR1mevmQnvNH/wQxpWiLJA2/W2a8yn1tTqpD3XcTudyOyRWTEyYRokqziFpKm3OUcfgaDRW3PT/AvVC+rLMlPeHYAAAAASUVORK5CYII="
               />
             </defs>
           </svg>
                }
            </div>
            
            <h3>{TransactionsHelper.getLabel(item?.attributes,accountId) != "No Name" ?
                   TransactionsHelper.getLabel(item?.attributes,accountId)
                   : item?.attributes?.description} {item?.attributes?.txn_type?.toLowerCase()  == "achincoming" ?  "(XXXX-" + item?.attributes.ach_account_number.slice(-4) + ")" : ""}
                   </h3>

            <p>{item?.attributes?.txn_type?.toLowerCase()  == "achincoming" ? "ACH IN" : (item?.attributes?.txn_type == "CORE" ? "Intrabank" : "ACH out")}</p>
            <p>  
                  {transactionType?.includes("Card Spend") ?
                    `${item?.attributes?.expense_category} ${item?.attributes?.acceptor_mcc ? " (" + item?.attributes?.acceptor_mcc + ")" : ""}`
                  : TransactionsHelper.getTransactionId(item?.attributes)}</p>
        </div>
        <div className="bot">
            <strong>${item?.attributes?.amount}</strong>
            <span>{item?.attributes?.status}</span>
            <span>{formatDate(item?.attributes?.created_at)}</span>
        </div>
    </div>
</div>
  )
}

export default TransactionTile
