import React, { useEffect, useState } from 'react';
import SimpleHeader from '../components/SimpleHeader';
import logo from "../assets/images/logo.png";
import { Link, useNavigate } from 'react-router-dom';
import usePost from '../hooks/usePost';
import endPoints from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';

const Register = () => {
    const [toggleEye, setToggleEye] = useState(false);
    const [toggleConfirmEye, setConfirmToggleEye] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPassType, setConfirmPassType] = useState("password");
    useEffect(() => {
        const user = localStorage.getItem("skyfi");
        const detail = localStorage.getItem("user");
        const role = localStorage.getItem("skyrole");
        if (user && detail && role) {
            navigate("/");
        }
    }, []);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
const navigate=useNavigate();
    const [userData, setUserData] = useState({
        email: "",
        password: "",
        password_confirmation: "",
        "client_id": "LtPQfxCksaEro428gTseixOLGbn2a4jZJc-yTx50KZU",
    });

    // const { data: postData, loading: postLoading, post } = usePost(endPoints.AUTH);
    const checkPasswordStrength = (password) => {
        const hasLowerCase = /[a-z]/.test(password);
        const hasUpperCase = /[A-Z]/.test(password);
        const hasDigit = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        const isLongEnough = password.length >= 8;

        if (hasLowerCase && hasUpperCase && hasDigit && hasSpecialChar && isLongEnough) {
            return 'Strong';
        } else if (isLongEnough && ((hasLowerCase && hasUpperCase) || (hasUpperCase && hasDigit) || (hasLowerCase && hasDigit) || (hasSpecialChar && (hasLowerCase || hasUpperCase || hasDigit)))) {
            return 'Medium';
        } else {
            return 'Weak';
        }
       
       
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value
        });
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ""
            });
        }
    };

    const validate = () => {
        let errors = {};
        if (!userData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
            errors.email = "Email address is invalid";
        }

        if (!userData.password) {
            errors.password = "Password is required";
        } 
        // else if (userData.password.length < 6) {
        //     errors.password = "Password must be at least 6 characters";
        // }

        if (!userData.password_confirmation) {
            errors.password_confirmation = "Password confirmation is required";
        } else if (userData.password !== userData.password_confirmation) {
            errors.password_confirmation = "Passwords do not match";
        }
       
            return errors;
        };
        
        const handleSubmit = async(e) => {
            e.preventDefault();
            const validationErrors = validate();
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                return;
            } 
            let checkPass=  checkPasswordStrength(userData?.password);
            
            if(checkPass=="Weak" || checkPass=="Medium"){
                toast.error("Password must include at least one lowercase letter, one uppercase letter, one digit, one special character, and be at least 8 characters long");
                return;
            }
        // else {
          
        //     console.log("Form data:", userData);
        //     setErrors({});
        // }
        try {
           
            setLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}${endPoints.AUTH}`,
                userData
               
            );
            if (response?.status == 200) {
               toast.success("User Created");
                const user = JSON.stringify(response?.data);
                localStorage.setItem("skyfi", user);
                navigate("/register-personal-detail")
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.error);
            setLoading(false);

        }
    };
    const handleEye = () => {
        setToggleEye(!toggleEye);
        if (passwordType == "password") {
            setPasswordType("text");
        }
        else setPasswordType("password");
    }
    const handleConfirmEye = () => {
        setConfirmToggleEye(!toggleConfirmEye);
        if (confirmPassType == "password") {
            setConfirmPassType("text");
        }
        else setConfirmPassType("password");
    }
    return (
        <>
            <SimpleHeader />
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-xl-11 col-xxl-10 m-auto main-page">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-lg-8 col-md-10">
                                <div className="card login-part">
                                    <div className="authincation-content">
                                        <div className="row no-gutters">
                                            <div className="col-xl-12">
                                                <div className="auth-form">
                                                    <div className="text-center mb-1">
                                                        <a className='cursor-pointer'>
                                                            <img src={logo} alt="Logo" />
                                                        </a>
                                                    </div>
                                                    <h4 className="text-center mb-4">Sign up your account</h4>
                                                    <form className="mt-4" onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label className="mb-1 form-label"> Email</label>
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                className="form-control"
                                                                placeholder="Enter Your Email"
                                                                value={userData.email}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.email && <p className="text-danger">{errors.email}</p>}
                                                        </div>
                                                        <div className=" position-relative">
                                                            <label className="mb-1 form-label">Password</label>
                                                            <input
                                                                name='password'
                                                                type={passwordType}
                                                                id="dz-password"
                                                                className="form-control"
                                                                placeholder="Enter Your Password"
                                                                value={userData.password}
                                                                onChange={handleChange}
                                                            />
                                                            <span className="show-pass eye">
                                                                {!toggleEye && <i className="fa fa-eye-slash" onClick={handleEye} />}
                                                                {toggleEye && <i className="fa fa-eye" onClick={handleEye} />}
                                                            </span>
                                                        </div>
                                                            {errors.password && <p className="text-danger">{errors.password}</p>}
                                                        <div className="mt-4 position-relative">
                                                            <label className="mb-1 form-label">
                                                                Confirm Password
                                                            </label>
                                                            <input
                                                                name='password_confirmation'
                                                                type={confirmPassType}
                                                                id="dz-password"
                                                                className="form-control"
                                                                placeholder="Confirm Your Password"
                                                                value={userData.password_confirmation}
                                                                onChange={handleChange}
                                                            />
                                                            <span className="show-pass eye">
                                                                {!toggleConfirmEye && <i className="fa fa-eye-slash" onClick={handleConfirmEye} />}
                                                                {toggleConfirmEye && <i className="fa fa-eye" onClick={handleConfirmEye} />}
                                                            </span>
                                                        </div>
                                                            {errors.password_confirmation && <p className="text-danger">{errors.password_confirmation}</p>}
                                                        <div className="text-center mt-4">
                                                            <button type="submit" className="btn" disabled={loading}>
                                                              {loading?"loading": "Sign Up"}
                                                            </button>
                                                        </div>
                                                    </form>
                                                    <div className="new-account mt-3">
                                                        <p>
                                                            Already have an account?
                                                            <Link to="/login" onClick={()=>{localStorage.clear()}}>Sign In</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;
