import React, { useEffect, useState } from 'react'
import MainHeader from '../components/MainHeader'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Modal from 'react-responsive-modal';
import baseUrl from '../baseUrl';
import endPoints from '../constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import useFetch from '../hooks/useFetch';
import { Bars } from 'react-loader-spinner';

const ContactDetails = () => {
    const location = useLocation();
    console.log(location);
    const navigate = useNavigate();
    const params = useParams();
    console.log("param contact details", params);
    const [open, setOpen] = useState(false);
    const [contactDetails, setContactDetail] = useState(null);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [loading, setLoading] = useState(false);
    const { data: contacts, dataLoading: contactDataLoading } = useFetch(`${endPoints.CONTACTS}?account_id=${params?.acc_id}`);
    useEffect(() => {
        if (contacts) {
            let findContact = contacts?.data?.find((item) => {
                if (item?.attributes?.uuid == params?.contact_id) {
                    return item
                }
            })
            setContactDetail(findContact)
           
        }
    }, [contacts])
    const handleDeleteContact = async () => {
        try {
            setLoading(true);
            const user = localStorage.getItem("skyfi");
            const tk = JSON.parse(user);
            const resp = await axios.delete(`${process.env.REACT_APP_BASE_URL}${endPoints.CONTACTS}/${params?.contact_id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${tk.access_token}`
                    }
                }
            );
            setLoading(false);
            if (resp?.status == 200) {
                toast.success("Contact deleted");
               
                navigate(`/view-contacts/${params?.acc_id}`)
            }

        } catch (error) {
            setLoading(false)
            console.log(error);
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.error[0]?.errorDesc);
        }
    }
    return (
        <>
            <MainHeader />
            {!contactDataLoading && <div className="content-body default-height">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-11 col-xxl-10 m-auto main-page">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card plan-list">
                                        <div className="card-header d-sm-flex flex-wrap d-block pb-0 border-0">
                                            {/* <div className="me-auto pe-3 mb-3">
                                              <h4 className="text-black fs-20 mb-3">Contacts</h4>
                                          </div> */}
                                        </div>
                                        <div className="card-body  pt-2">
                                            <div className="me-auto pe-3 mb-3 d-flex gap-2">
                                                <h4 className="text-black fs-20 mb-3">Name:-</h4>
                                                <h4 className="text-black fs-20 mb-3 text-text-capitalize">{contactDetails?.attributes?.first_name} {contactDetails?.attributes?.last_name}</h4>
                                            </div>
                                            {/* <div className="me-auto pe-3 mb-3">
                                          </div> */}
                                            <div className="me-auto pe-3 mb-3 d-flex gap-2">
                                                <h4 className="text-black fs-18 mb-3">Phone:-</h4>
                                                <h4 className="text-black fs-18 mb-3">{contactDetails?.attributes?.phone}</h4>
                                            </div>
                                            <div className="me-auto pe-3 mb-3 d-flex gap-2">
                                                <h4 className="text-black fs-18 mb-3">Email:-</h4>
                                                <h4 className="text-black fs-18 mb-3">{contactDetails?.attributes?.email}</h4>
                                            </div>
                                            <div className="row">

                                                <div className="col-xl-12 mt-2">
                                                    <ul className="tab-links">
                                                        <li onClick={() => { navigate(`/contact-transfer-money/${params?.acc_id}/${params?.contact_id}`) }}>
                                                            <a className='cursor-pointer' >
                                                                Send Money
                                                                <svg
                                                                    width={19}
                                                                    height={19}
                                                                    viewBox="0 0 19 19"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17.832 9.49976H1.16537"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeMiterlimit={10}
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                        <li onClick={() => { navigate(`/card-transaction/${params?.acc_id}/${params?.contact_id}_contact`) }}>
                                                            <a className='cursor-pointer' >
                                                                History
                                                                <svg
                                                                    width={19}
                                                                    height={19}
                                                                    viewBox="0 0 19 19"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17.832 9.49976H1.16537"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeMiterlimit={10}
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                        <li onClick={() => { navigate(`/update-contact/${params?.acc_id}/${params?.contact_id}`, { state: { details: contactDetails, uuid: location?.state?.uuid } }) }}>
                                                            <a className='cursor-pointer' >
                                                                Edit Contact
                                                                <svg
                                                                    width={19}
                                                                    height={19}
                                                                    viewBox="0 0 19 19"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17.832 9.49976H1.16537"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeMiterlimit={10}
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                        <li onClick={onOpenModal}>
                                                            <a className='cursor-pointer' >
                                                                Delete
                                                                <svg
                                                                    width={19}
                                                                    height={19}
                                                                    viewBox="0 0 19 19"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M17.832 9.49976H1.16537"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeMiterlimit={10}
                                                                        strokeLinecap="round"
                                                                    />
                                                                    <path
                                                                        d="M10.5061 1.52051L17.353 8.3653C17.5029 8.51352 17.6219 8.69001 17.7031 8.88455C17.7843 9.07909 17.8262 9.28781 17.8262 9.49863C17.8262 9.70945 17.7843 9.91817 17.7031 10.1127C17.6219 10.3073 17.5029 10.4837 17.353 10.632L10.5061 17.4788"
                                                                        stroke="black"
                                                                        strokeWidth="1.375"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </a>
                                                        </li>



                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <Modal open={open} onClose={onCloseModal} center>
                <div className="p-5">
                    <div className="">
                        <h4>Do you want to delete this Contact</h4>
                        <button className='btn btn-primary' onClick={onCloseModal}>Cancel</button>
                        {/* <button className='btn btn-primary mx-3' > Sue</button> */}
                        <button className='btn btn-primary mx-3' onClick={handleDeleteContact} disabled={loading}> {loading ? "Loading" : "Sure"}</button>
                    </div>

                </div>
            </Modal>
            <Bars
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass="bars"
                visible={contactDataLoading}
            />
        </>
    )
}

export default ContactDetails